// import type { DropdownProps } from "utils/types/shared.types";

import { DropdownProps } from "utils/types/shared.types";

const Dropdown: React.FC<DropdownProps> = ({
  children,
  trigger,
  options,
  contentClassName = "",
  dropdownClassname = "",
  open,
  propagationStop,
  setOpen,
}) => {
  return (
    <div
      className={`relative ${contentClassName}`}
      onMouseEnter={() => trigger === "hover" && setOpen(true)}
      onMouseLeave={() => trigger === "hover" && setOpen(false)}
      onClick={(e) => {
        setOpen(!open);
        propagationStop && e.stopPropagation();
      }}
    >
      {children}

      <div
        className={`${
          open ? "h-fit max-h-screen" : "max-h-0"
        } bg-secondary-500 absolute right-0 z-30  overflow-hidden transition-all duration-300 ease-in ${dropdownClassname}`}
      >
        {options?.map((option) => option)}
      </div>
    </div>
  );
};

export default Dropdown;
