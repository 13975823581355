import type { ButtonProps } from "utils/types/shared.types";

const Button: React.FC<ButtonProps> = ({
  small = false,
  children,
  wrapper_classname = "",
  button_classname = "",
  bg_fill_hover = false,
  ...btn_props
}) => {
  return (
    <div
      className={`group flex items-center justify-center transition-all duration-300 ${wrapper_classname}`}
    >
      <button
        type="submit"
        className={`w-full transition-all duration-300 ${
          small ? "h-fit" : "h-16"
        } p-0.5 ${button_classname}`}
        {...btn_props}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
