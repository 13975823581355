export enum SOCIALS {
	DISCORD = "https://community.hashmail.dev",
	DOCS = "https://docs.hashmail.dev/docs/installing-the-widget",
	HASHMAIL_APP = "https://app.hashmail.dev",
	LANDING = "https://console.hashmail.dev",
	TELEGRAM = "https://t.me/hashmail_tg",
	LINKEDIN = "https://www.linkedin.com/company/0xhashmail",
	TWITTER = "https://twitter.com/0x_hashmail",
	BLOG = "https://hashmail.dev/blog",
	CAREERS = "https://hashmail.dev/careers",
	PARTNER_WITH_US = "https://calendly.com/d/zt4-h6d-ydr/hashmail-partnership",
}

export const SENDER_ADDRESS = "0xdeb85cf14a17f098e370ceec188B0a30e8BF5B26";
export const BEARER_TOKEN =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiIDogImF1dGhlbnRpY2F0ZWQiLCAiZXhwIiA6IDE3MDE3NzI0OTIuOTAzMzYzLCAic3ViIiA6ICI3NmYxZjIzZS00ZjA5LTQ3NTItYjhlZi0yYzJlY2U0MTE3NzIiLCAicm9sZSIgOiAiYXV0aGVudGljYXRlZCIsICJkZXNjcmlwdGlvbiIgOiAiZGVmYXVsdCB0b2tlbiIsICJpc3N1ZWRfYnkiIDogImhhc2htYWlsLWFwaS1pc3N1ZXIifQ.cSne1RlTIjs5kvFQLltX0bnjeHhjw87TPl_WPIIHb9o";
export enum ECOSYSTEM_TYPE {
	EVM = "EVM",
	SOLANA = "Solana",
}

export const EVM_ECOSYSTEM_CHAINS = ["ethereum", "polygon", "binance"];
export const SOLANA_CHAINS = ["solana"];
export enum WALLET_NAMES {
	injected = "metamask",
	metamask = "metamask",
	binance = "binance",
	coinbasewallet = "coinbase",
	walletconnect = "walletconnect",
	phantom = "phantom",
}
