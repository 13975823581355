import React, { useEffect, useState } from "react";
import MainLayout from "layouts/main-layout";
import { PlaygroundScreen } from "components/playground";
import { Toaster } from "react-hot-toast";
import { WalletConnectModal } from "components/shared/wallet-connect-modal";
import axios from "axios";
import * as cookie from "cookie";
import { removeCookie as _removeCookie } from "utils/cookies";

const widgetURL = process.env.NEXT_PUBLIC_WIDGET_URL;
const defaultDappId = process.env.NEXT_PUBLIC_DAPP_ID;

export default function App({ dappId, configData, removeCookie }: any) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);

  const messageListenerCallback = (event: MessageEvent) => {
    switch (event.data) {
      case "HASHMAIL-CONNECT-HANDLER":
        setModalOpen(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("message", messageListenerCallback);

    if (removeCookie) {
      // TODO: remove cookie
      _removeCookie("hashmailCookie");
    }

    () => window.removeEventListener("message", messageListenerCallback);
  }, []);

  React.useEffect(() => {
    if (removeCookie) {
      // TODO: remove cookie
      _removeCookie("hashmailCookie");
    }
  }, [removeCookie]);

  return (
    <MainLayout>
      <script
        dangerouslySetInnerHTML={{
          __html: `
              const baseURL =
          "${widgetURL}";
        let dapp_id = "${dappId}"; // replace with dapp_id provided by hashmail
        !(function () {
          window.hashmail || (window.hashmail = []), (window.hashmail.queue = []);
          let i = ["load", "identify", "track"],
            t = function (i) {
              return function () {
                (a = Array.prototype.slice.call(arguments)),
                  a.unshift(i),
                  window.hashmail.queue.push(a);
              };
            };
          for (var e = 0; i.length > e; e++) window.hashmail[i[e]] = t(i[e]);
          (hashmail.methods = i),
            (window.hashmail.load = function (i, t) {
              (window.hashmail.dapp_id = i), (window.hashmail.settings = t);
              var e = document,
                s = e.getElementsByTagName("script")[0],
                h = e.createElement("script");
              (h.type = "text/javascript"),
                (h.async = !0),
                (h.src = baseURL),
                s.parentNode.insertBefore(h, s);
            }),
            (window.hashmail.identify = (i) => {
              (window.hashmail.wallet_address = i),
                localStorage.setItem("hashmail-wallet_address", i);
            }),
            window.hashmail.load(dapp_id);
        })();
          `,
        }}
        type="text/javascript"
      />
      <WalletConnectModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isLogin={isLogin}
        setIsLogin={setIsLogin}
      />
      <Toaster />
      <PlaygroundScreen configData={configData} />
    </MainLayout>
  );
}

export async function getServerSideProps(context: any) {
  const { query } = context;
  const { dappId } = query;

  let hashmailCookie;
  let removeCookie;

  if (context?.req?.headers?.cookie !== undefined) {
    hashmailCookie =
      cookie.parse(context.req.headers.cookie)?.hashmailCookie || null;
    removeCookie = false;
  }

  if (hashmailCookie) {
    const parsedCookie = JSON.parse(hashmailCookie);
    if (
      (!dappId && parsedCookie.dapp_id !== defaultDappId) ||
      (dappId && parsedCookie.dapp_id !== dappId)
    ) {
      // TODO: remove cookie
      removeCookie = true;
    }
  }

  const bearerToken = process.env.NEXT_PUBLIC_AUTH_KEY;

  const apiUrl = `${process.env.NEXT_PUBLIC_PROXY_URL}/widget/auth/config`;

  try {
    const response = await axios.post(
      apiUrl,
      {
        dapp_id_input: dappId || defaultDappId,
      },
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );

    const configData = response.data;

    return {
      props: {
        dappId: dappId || defaultDappId,
        configData,
        removeCookie: removeCookie || "",
      },
    };
  } catch (error) {
    console.error("api call failed:", error);

    return {
      props: {
        configData: null,
      },
    };
  }
}
