import { ChatIcon, MailIcon, NotificationIcon } from "public/icons";
import React, { useContext, useMemo } from "react";
import { DottedLineImage } from "public/images/shared";
import GlobalContext from "context/GlobalContext";

const Playground = ({ configData }: any) => {
  const [isWidgetOpened, setIsWidgetOpened] = React.useState<boolean>(false);
  const [isMessageSent, setIsMessageSent] = React.useState<boolean>(false);
  const [dappInfo, setDappInfo] = React.useState<any>(null);

  const { walletIsConnected, connectedAddress } = useContext(GlobalContext);

  //!handle identify

  const messageListenerCallback = (event: MessageEvent) => {
    switch (event.data) {
      case "hashmail-widget_opened":
        setIsWidgetOpened(true);
        break;
      case "playground-message_sent":
        setIsMessageSent(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("message", messageListenerCallback);
    () => window.removeEventListener("message", messageListenerCallback);
  }, []);

  React.useEffect(() => {
    window.addEventListener("message", messageListenerCallback);
    () => window.removeEventListener("message", messageListenerCallback);
  }, []);

  const LANDING_PAGE_TEXT = useMemo<any[]>(
    () => [
      {
        id: "1",
        heading: "Test your GPT-powered chatbot",
        icon: <ChatIcon />,
        description: (
          <div className="flex flex-col">
            This chatbot is trained on your docs. Ask questions by clicking on{" "}
            <span className="font-semibold">“send us a message”</span>
          </div>
        ),
      },
      {
        id: "2",
        heading: "Forward notifications to email, TG and Discord ",
        icon: <MailIcon />,
        description: (
          <span className="flex">
            Click on the forwarding tab to securely setup forwarding of <br />
            notifications from your wallet address
          </span>
        ),
      },
      {
        id: "3",
        heading: "Send personalized wallet notifications",
        icon: <NotificationIcon />,
        description: (
          <div className="flex flex-col items-start">
            Send important comms (transaction updates, alerts and reminders){" "}
            <br /> to wallet addresses on your dapp.
            <br />
            {/* <button className=" text-hash-red-gradient font-semibold underline decoration-primary-salmon">
              <span>Click here to send a test notification.</span>
            </button> */}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <section className="flex h-full flex-col gap-y-6 px-6 pl-12 md:px-24">
      <div className="pt-16 text-3xl font-semibold text-dark-gray-100">
        <p className="text-4xl "> Welcome to the widget demo for</p>
        <div className="ite flex gap-x-4 py-6">
          <span
            className="text-4xl"
            style={{ color: configData?.widget_configuration?.widgetTheme }}
          >
            {configData?.dapp_name}
          </span>
        </div>
      </div>
      <div>
        {LANDING_PAGE_TEXT.map((props) => (
          // eslint-disable-next-line react/jsx-key
          <div className="flex flex-col gap-y-4 pb-14 text-dark-gray-100">
            <div className="flex flex-row  items-center gap-x-3">
              <span className=" m-1 flex text-lg">{props.icon}</span>
              <p className="text-lg font-semibold">{props.heading}</p>
            </div>
            <div className="font-poppins text-sm font-medium">
              {props.description}
            </div>
          </div>
        ))}
      </div>

      {!walletIsConnected && (
        <figure className=" absolute right-44 top-28 -z-10  hidden text-lg text-dark-gray-100 lg:block">
          <DottedLineImage />
        </figure>
      )}
    </section>
  );
};

export default Playground;
