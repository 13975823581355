export function setCookie(name: any, value: any, days: any) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  window.document.cookie = `${name}=${value};expires=${expires.toUTCString()}`;
}

export function removeCookie(name: any) {
  window.document.cookie = `${name}=;Max-Age=-9999999;`;
}

export function getCookie(cookieName: any) {
  const cookies = window?.document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${cookieName}=`)) {
      return cookie.substring(cookieName.length + 1);
    }
  }
  return null;
}