/* eslint-disable @next/next/no-img-element */

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { WalletConnectModalProps } from "utils/types/shared.types";
import {
  ECOSYSTEM_TYPE,
  EVM_ECOSYSTEM_CHAINS,
} from "utils/constants/shared-constants";
import { ChevronDownIcon } from "public/icons";
import { SpinnerIcon } from "utils/icons";
import { ErrorToast } from "utils/notifications/toast";
import { EVM, Solana } from ".";
import Modal from "../modal";
import Dropdown from "../dropdown";
import GlobalContext from "context/GlobalContext";
import Spinner from "utils/icons/spinner";

const WalletConnectModal: React.FC<WalletConnectModalProps> = ({
  modalOpen,
  isLogin,
  setIsLogin,
  setModalOpen,
}) => {
  const EVMRef = useRef<React.ElementRef<typeof EVM>>(null);
  const SolanaRef = useRef<React.ElementRef<typeof Solana>>(null);

  const [selectedOption, setSelectedOption] = useState<"EVM" | "Solana">(
    ECOSYSTEM_TYPE.EVM
  );

  const [dropdown, setDropdown] = useState<boolean>(false);
  const [walletSelected, setWalletSelected] = useState<boolean>(false);
  const [walletConnectError, setWalletConnectError] = useState<boolean>(false);
  const [address, setAddress] = useState<string | null>(null);
  const [walletName, setWalletName] = useState<string>("wallet");
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [signature, setSignature] = useState<string | null>(null);
  const [signupSuccess, setSignupSuccess] = useState<boolean>(false);
  const [signErrorState, setSignErrorState] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(false);

  const {
    walletIsConnected,
    setWalletIsConnected,
    connectedAddress,
    setConnectedAddress,
  } = React.useContext(GlobalContext);

  useEffect(() => {
    setConnectedAddress(address);
  }, [address]);
  useEffect(() => {
    setWalletIsConnected(isConnected);
  }, [isConnected]);

  const disconnectWalletHandler = useCallback(() => {
    EVMRef.current?.disconnectHandler();
    SolanaRef.current?.disconnectHandler();
    setWalletSelected(false);
    setAddress(null);
    setIsConnected(false);
    setSignupSuccess(false);
    setSignErrorState(false);
    setWalletConnectError(false);
    setFormLoading(false);
    setSelectedOption("EVM");
  }, [EVMRef, SolanaRef]);

  useEffect(() => {
    if (address && isConnected) window.hashmail?.identify(address);
  }, [address, isConnected]);

  //!handle disconnect

  useEffect(() => {
    if (address) {
      window.hashmail?.identify(address);
    } else if (window.hashmail?.disconnect) {
      window.hashmail?.disconnect();
    }
    setIsloading(false);
  }, [address, isConnected]);

  const OPTIONS = useMemo<React.ReactNode[]>(
    () => [
      <button
        key="EVM-based"
        className="flex w-full justify-between  gap-x-4 px-2 py-4 "
        onClick={() => setSelectedOption(ECOSYSTEM_TYPE.EVM)}
      >
        <span>EVM</span>
        <div className="flex gap-x-2">
          <img
            src={`/images/shared/ethereum_logo.svg`}
            alt={`hashmail | ethereum-icon`}
            className="h-5 w-5"
          />
          <img
            src={`/images/shared/polygon_logo.svg`}
            alt={`hashmail | polygon-icon`}
            className="h-5 w-5"
          />
          <img
            src={`/images/shared/binance.svg`}
            alt={`hashmail | binance-icon`}
            className="h-5 w-5"
          />
        </div>
      </button>,
      <button
        key="Solana"
        className="flex w-full justify-between  gap-x-12 px-2 py-4"
        onClick={() => setSelectedOption(ECOSYSTEM_TYPE.SOLANA)}
      >
        <span>Solana</span>
        <div>
          <img
            src={`/images/shared/solana_logo.svg`}
            alt={`hashmail | solana-icon`}
            className="h-5 w-5"
          />
        </div>
      </button>,
      <button
        key="near"
        className="flex w-full justify-between  gap-x-12 px-2 py-4 text-dark-gray-100"
        onClick={() => ErrorToast("near protocol is coming soon")}
      >
        <span>NEAR protocol</span>
        <div className="flex gap-x-3">
          <span>coming soon</span>
          <img
            src={`/images/shared/near_logo.svg`}
            alt={`hashmail | near-icon`}
            className="h-5 w-5"
          />
        </div>
      </button>,
      <button
        key="Cosmos"
        className="flex w-full justify-between  gap-x-12 px-2 py-4 text-dark-gray-100"
        onClick={() => ErrorToast("Cosmos is coming soon")}
      >
        <span>Cosmos</span>
        <div className="flex gap-x-3">
          <span>coming soon</span>
          <img
            src={`/images/shared/cosmos_logo.svg`}
            alt={`hashmail | cosmos-icon`}
            className="h-5 w-5"
          />
        </div>
      </button>,
      <button
        key="Hedera Hashgraph"
        className="flex w-full justify-between  gap-x-12 px-2 py-4 text-dark-gray-100"
        onClick={() => ErrorToast("Hedera Hashgraph is coming soon")}
      >
        <span>Hedera Hashgraph</span>
        <div className="flex gap-x-3">
          <span>coming soon</span>
          <img
            src={`/images/shared/hedera_logo.svg`}
            alt={`hashmail | hedera_logo-icon`}
            className="h-5 w-5"
          />
        </div>
      </button>,
      <button
        key="Algorand"
        className="flex w-full justify-between  gap-x-12 px-2 py-4 text-dark-gray-100"
        onClick={() => ErrorToast("Algorand is coming soon")}
      >
        <span>Algorand</span>
        <div className="flex gap-x-3">
          <span>coming soon</span>
          <img
            src={`/images/shared/algorand.svg`}
            alt={`hashmail | algorand-icon`}
            className="h-5 w-5"
          />
        </div>
      </button>,
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //!
  //! Components
  //!

  const EcosystemSelected = useCallback((ecosystem: "EVM" | "Solana") => {
    switch (ecosystem) {
      case ECOSYSTEM_TYPE.EVM:
        return (
          <div className="flex gap-x-2">
            <img
              src={`/images/shared/ethereum_logo.svg`}
              alt={`hashmail | ethereum-icon`}
              className="h-5 w-5"
            />
            <img
              src={`/images/shared/polygon_logo.svg`}
              alt={`hashmail | polygon-icon`}
              className="h-5 w-5"
            />
            <img
              src={`/images/shared/binance.svg`}
              alt={`hashmail | binance-icon`}
              className="h-5 w-5"
            />
          </div>
        );

      case ECOSYSTEM_TYPE.SOLANA:
        return (
          <div>
            <img
              src={`/images/shared/solana_logo.svg`}
              alt={`hashmail | solana-icon`}
              className="h-5 w-5"
            />
          </div>
        );
      default:
        return EVM_ECOSYSTEM_CHAINS;
    }
  }, []);
  useEffect(() => {
    if (isConnected) {
      setModalOpen(false);
    }
  }, [isConnected]);

  return (
    <Modal
      open={modalOpen}
      setOpen={setModalOpen}
      disableClose
      className="relative z-0 m-auto flex min-h-6/10-screen w-1/6 min-w-4xl rounded-lg bg-med-black p-px text-white"
    >
      <div className="flex h-full w-full items-center justify-center rounded-lg bg-hash-dark">
        <>
          <button className="absolute right-8 top-7 h-4 w-4">
            <button
              onClick={() => {
                setModalOpen(false);
                setIsloading(false);
              }}
            >
              x
            </button>
          </button>

          <div className="mx-auto flex w-8/12 items-center justify-around ">
            <div>
              {isLoading || address ? (
                <div
                  className={`flex w-full flex-col items-center justify-center text-center`}
                >
                  {isLoading && (
                    <div className="flex flex-col items-center justify-center gap-y-8">
                      <figure className="h-7 w-7 text-lg text-primary-salmon">
                        <Spinner />
                      </figure>
                      <p className="text-body-large max-w-md">
                        look for a connect request on your web-browser or mobile
                        wallet.
                        <span className="text-gray-100">
                          please accept to continue.
                        </span>
                      </p>
                      <button
                        className="text-body-small mx-auto flex text-center font-light text-gray-100 underline"
                        onClick={() => {
                          setIsloading(false);
                          setWalletSelected(false);
                        }}
                      >
                        go back to choose another wallet
                      </button>
                    </div>
                  )}
                  {address ? (
                    <div className="pb-6">
                      <h4 className="mb-4 bg-hash-red bg-clip-text text-2xl font-semibold text-transparent">
                        welcome to #hashmail
                      </h4>
                      <p className="text-sm">{address}</p>
                      <button
                        className="mx-auto flex text-center text-xs font-light text-gray-100 underline"
                        onClick={() => {
                          disconnectWalletHandler();
                          setModalOpen(false);
                          localStorage.hashmailWalletConnected = "";
                        }}
                      >
                        disconnect wallet
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : (
                <></>
              )}

              <div
                className={`${walletConnectError || !address ? "" : "hidden "}${
                  !(address || walletSelected) ? "" : "hidden "
                }h-124 w-full px-10 pt-10`}
              >
                <p className="mb-4 text-center text-opacity-60">
                  choose your preferred ecosystem
                </p>
                <div className="flex flex-col justify-center pb-5 ">
                  <Dropdown
                    trigger="click"
                    open={dropdown}
                    setOpen={setDropdown}
                    options={OPTIONS}
                    contentClassName="h-full  w-full text-sm bg-hash-dark rounded bg-opacity-5 py-4 px-3 flex items-center justify-between gap-x-4 cursor-pointer hover:bg-opacity-10   border-1 border-primary-vermilion"
                    dropdownClassname=" flex flex-col w-full divide-y px-3  divide-dark-gray-100 border-1 border-t-0 border-dark-gray-100 rounded bg-hash-dark top-full z-50 whitespace-nowrap mt-[1px]"
                  >
                    <span className="flex content-center justify-center pl-2 text-center">
                      {selectedOption}
                    </span>
                    <div className="flex gap-x-2">
                      <span>{EcosystemSelected(selectedOption)}</span>
                      <span
                        className={` mt-0.5 flex h-5 w-5 transition-all ${
                          dropdown ? "rotate-180" : "rotate-0"
                        }`}
                      >
                        <ChevronDownIcon />
                      </span>
                    </div>
                  </Dropdown>
                </div>
                <div
                  className={`${selectedOption !== "Solana" ? "hidden" : ""}`}
                >
                  <Solana
                    ref={SolanaRef}
                    address={address}
                    formLoading={formLoading}
                    isConnected={isConnected}
                    isLogin={isLogin}
                    modalOpen={modalOpen}
                    setAddress={setAddress}
                    setFormLoading={setFormLoading}
                    setIsConnected={setIsConnected}
                    setSignErrorState={setSignErrorState}
                    setSignature={setSignature}
                    setSignupSuccess={setSignupSuccess}
                    setWalletConnectError={setWalletConnectError}
                    setWalletName={setWalletName}
                    setWalletSelected={setWalletSelected}
                    setIsloading={setIsloading}
                  />
                </div>
                <div className={`${selectedOption !== "EVM" ? "hidden" : ""}`}>
                  <EVM
                    ref={EVMRef}
                    address={address}
                    formLoading={formLoading}
                    isConnected={isConnected}
                    isLogin={isLogin}
                    modalOpen={modalOpen}
                    setAddress={setAddress}
                    setFormLoading={setFormLoading}
                    setIsConnected={setIsConnected}
                    setSignErrorState={setSignErrorState}
                    setSignature={setSignature}
                    setSignupSuccess={setSignupSuccess}
                    setWalletConnectError={setWalletConnectError}
                    setWalletName={setWalletName}
                    setWalletSelected={setWalletSelected}
                    setIsloading={setIsloading}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default WalletConnectModal;
