/* eslint-disable react/jsx-no-target-blank */
import { PLAYGROUND_CONSTANTS_TYPE } from "utils/types/components.types";
import { SOCIALS } from "./shared-constants";

export const NAVBAR_ROUTES = [
  {
    external: true,
    href: SOCIALS.LANDING,
    content: "signup to hashmail console",
  },

  {
    external: true,
    href: SOCIALS.DOCS,
    content: "view docs",
  },
  {
    external: true,
    href: SOCIALS.PARTNER_WITH_US,
    content: "book a demo",
  },
];

export enum SOCIAL_LINKS {
  API_DOCS = "https://docs.hashmail.dev/docs/installing-the-widget",
  BOOK_DEMO_CALL = "https://calendly.com/d/zt4-h6d-ydr/hashmail-partnership",
}
