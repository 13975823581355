/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */

import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import { useWalletModal as useSolanaWalletModal } from "@solana/wallet-adapter-react-ui";
import { useWallet as useSolanaWallet } from "@solana/wallet-adapter-react";
import Button from "../button";
import type {
	PhantomProviderType,
	WalletConnectProps,
	WalletConnectRef,
} from "utils/types/shared.types";

export const getPhantomProvider = (): PhantomProviderType | undefined => {
	if (typeof window != "undefined")
		if ("phantom" in window) {
			const anyWindow: any = window;
			const provider = anyWindow.phantom?.solana;

			if (provider?.isPhantom) {
				return provider as PhantomProviderType;
			}
		}
};

const Solana: React.ForwardRefRenderFunction<
	WalletConnectRef,
	WalletConnectProps
> = (
	{
		address,
		isConnected,
		modalOpen,
		setAddress,
		setWalletSelected,
		setWalletConnectError,
		setSignErrorState,
		setSignupSuccess,
		setWalletName,
		setFormLoading,
		setSignature,
		setIsConnected,
		setIsloading,
	},
	ref
) => {
	useImperativeHandle(ref, () => ({
		disconnectHandler,
	}));
	const { setVisible } = useSolanaWalletModal();

	const [isPhantomConnected, setIsPhantomConnected] = useState<boolean>(false);
	const [phantomWalletAddress, setPhantomWalletAddress] = useState<
		string | null
	>(null);
	const [phantomProvider, setPhantomProvider] =
		useState<PhantomProviderType | null>(null);

	const isPhantomWallet = getPhantomProvider();

	const {
		publicKey,
		connected: solanaWalletConnected,
		disconnect: solanaWalletDisconnect,
		signMessage: solanaSignMessage,
		connecting,
	} = useSolanaWallet();

	useEffect(() => {
		const provider = getPhantomProvider();
		if (provider) setPhantomProvider(provider);
		else setPhantomProvider(null);
	}, [phantomWalletAddress]);

	useEffect(() => {
		if (connecting) {
			setIsloading(true);
		} else {
			setWalletSelected(false);
			setIsloading(false);
		}
	}, [connecting]);

	useEffect(() => {
		if (publicKey != null) setAddress(publicKey?.toString()!);
		else if (phantomWalletAddress != null) {
			localStorage.hashmailWalletConnected = "phantom";
			setAddress(phantomWalletAddress);
		} else if (!address) {
			setAddress(null);
		}
	}, [publicKey, phantomWalletAddress]);

	useEffect(() => {
		if (isPhantomWallet?.isPhantom && isPhantomWallet?.isConnected) {
			setWalletName("phantom");
		} else {
			const walletconnectobj = JSON.parse(
				window.localStorage.getItem("walletconnect") ?? JSON.stringify({})
			);
			setWalletName(walletconnectobj?.name ?? "wallet");
		}
	}, [isPhantomWallet]);

	useEffect(() => {
		if (
			solanaWalletConnected ||
			(phantomProvider?.isConnected && phantomProvider?.isPhantom)
		) {
			setIsConnected(true);
		}
	}, [solanaWalletConnected, phantomProvider, isPhantomConnected]);

	const connectPhantomWalletHandler = useCallback(async () => {
		try {
			//@ts-ignore
			const { solana } = window;
			if (solana) {
				if (solana?.isPhantom) {
					setWalletSelected(true);
					const response = await solana.connect({ onlyIfTrusted: false });

					setIsPhantomConnected(true);
					setPhantomWalletAddress(response.publicKey.toString());
				}
			}
		} catch (error) {
			console.error(error);
			setIsPhantomConnected(false);
			setWalletConnectError(true);
			setWalletSelected(false);
		}
	}, []);

	const disconnectHandler = async () => {
		console.log("disconnect-solana");
		await solanaWalletDisconnect();

		setPhantomWalletAddress(null);
	};

	const connectSolanaWallet = () => {
		setVisible(true);
	};

	useEffect(() => {
		if (
			localStorage.hashmailWalletConnected === "phantom" &&
			(window as any).solana
		) {
			connectPhantomWalletHandler();
		}
	}, [phantomProvider]);

	return (
		<div>
			<div className='mx-auto mt-5 w-full'>
				<Button
					type='submit'
					wrapper_classname='  border-1 rounded border-dark-gray-100  hover:border-primary-vermilion'
					button_classname='h-auto py-3 grid grid-cols-6 place-content-around place-items-center'
					onClick={() => {
						connectPhantomWalletHandler();
					}}>
					<img
						src={`/images/shared/phantom.svg`}
						alt={`hashmail | phantomwallet-icon`}
						className='col-span-2 h-8 w-8'
					/>
					<span className='col-span-3 -ml-6 flex h-full  w-full items-center justify-start  text-start text-base font-semibold'>
						phantom
					</span>
				</Button>
			</div>
			<p className='mb-4 mt-8 text-center text-gray-100 text-opacity-60'>
				or choose from <span className='text-hash-light'>15+ integrated</span>{" "}
				wallets <br />
			</p>
			<div className='mx-auto mt-5 w-full'>
				<Button
					type='submit'
					wrapper_classname='  border-1 rounded border-dark-gray-100  hover:border-primary-vermilion'
					button_classname='h-auto py-3 grid grid-cols-6 place-content-around place-items-center '
					onClick={() => {
						connectSolanaWallet();
						setWalletSelected(true);
					}}>
					<img
						src={`/images/shared/solana_logo.svg`}
						alt={`hashmail | solana-icon`}
						className='col-span-2 h-8 w-8'
					/>
					<span className='col-span-3 -ml-6 flex h-full w-full items-center justify-start whitespace-nowrap text-start  text-base font-semibold'>
						solana wallet adapter
					</span>
				</Button>
			</div>
		</div>
	);
};

Solana.displayName = "Solana";
export default forwardRef(Solana);
