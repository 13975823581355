/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */

import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";

import {
  useAccount as useEVMAccount,
  useConnect as useEVMConnect,
  useDisconnect as useEVMDisconnect,
  useSignMessage as useEVMSignMessage,
} from "wagmi";

import { WALLET_NAMES } from "utils/constants/shared-constants";
import Button from "../button";
import type {
  WalletConnectProps,
  WalletConnectRef,
} from "utils/types/shared.types";

const EVM: React.ForwardRefRenderFunction<
  WalletConnectRef,
  WalletConnectProps
> = (
  {
    setWalletSelected,
    setSignature,
    setAddress,
    setWalletConnectError,
    setSignErrorState,
    setIsConnected,
    setWalletName,
    setIsloading,
  },
  ref
) => {
  useImperativeHandle(ref, () => ({
    disconnectHandler,
  }));

  const { disconnect: disconnectHandler } = useEVMDisconnect();
  const { connect, connectors, error: connectError } = useEVMConnect();
  const {
    signMessage,
    data: evmSignature,
    error: signError,
  } = useEVMSignMessage();
  const {
    address: evmAddress,
    isConnected: evmWalletIsConnected,
    connector,
  } = useEVMAccount();

  useEffect(() => {
    if (evmWalletIsConnected) {
      setIsConnected(true);
    }
  }, [evmWalletIsConnected]);

  useEffect(() => {
    if (evmWalletIsConnected) setIsloading(false);
  }, [evmWalletIsConnected]);

  useEffect(() => {
    if (evmSignature) setSignature(evmSignature);
  }, [evmSignature]);

  useEffect(() => {
    if (evmAddress) setAddress(evmAddress);
  }, [evmAddress]);

  useEffect(() => {
    setWalletConnectError(true);
    if (connectError) {
      setWalletSelected(false);
      setIsloading(false);
    }
  }, [connectError]);

  useEffect(() => {
    if (connector?.name && connector?.name.toLowerCase() != "walletconnect") {
      setWalletName(connector?.name ?? "wallet");
    }
  }, [connector]);

  useEffect(() => {
    if (signError) setSignErrorState(true);
    else setSignErrorState(false);
  }, [signError]);

  useEffect(() => {
    //? : preconnect logic if last time connected
    const wagmiConnected = localStorage["wagmi.connected"];
    const wagmiWallet = localStorage["wagmi.wallet"];
    const hashmailWalletConnected = localStorage.hashmailWalletConnected;
    if (
      wagmiConnected &&
      connectors &&
      wagmiWallet &&
      hashmailWalletConnected
    ) {
      if (hashmailWalletConnected === JSON.parse(wagmiWallet || "{}")) {
        const connector = connectors.filter((item: any) => {
          return item.id === JSON.parse(wagmiWallet);
        })[0];
        connect({ connector });
      }
    }
  }, [connectors]);

  return (
    <div>
      <div className="flex items-center justify-between gap-x-4">
        {connectors.map((connector) => {
          if (connector.name.toLowerCase() !== "walletconnect") {
            return (
              <div key={connector.id} className="">
                <Button
                  type="submit"
                  disabled={!connector.ready}
                  wrapper_classname=" border-1 rounded border-dark-gray-100 hover:border-primary-vermilion py-2 w-28"
                  button_classname="w-28 h-auto flex  flex-col items-center justify-center gap-4"
                  onClick={() => {
                    setIsloading(true);
                    connect({ connector });
                    setWalletSelected(true);
                    localStorage.hashmailWalletConnected = connector.id;
                  }}
                >
                  <img
                    src={`/images/shared/${
                      WALLET_NAMES[
                        connector.id.toLowerCase() as keyof typeof WALLET_NAMES
                      ]
                    }.svg`}
                    alt={`hashmail | ${connector.name}-icon`}
                    className="h-8 w-8"
                  />

                  <span>{connector.name.toLowerCase().split(" ")[0]}</span>
                </Button>
              </div>
            );
          }
        })}
      </div>
      <p className="mb-4 mt-8 text-center text-gray-100 text-opacity-60">
        or choose from <span className="text-hash-light">170+ integrated</span>{" "}
        wallets
      </p>
      <div className="mx-auto -mt-1 w-full">
        <Button
          type="submit"
          wrapper_classname="  border-1 rounded border-dark-gray-100  hover:border-primary-vermilion"
          button_classname="h-auto flex items-center justify-center gap-4  py-3"
          onClick={() => {
            setIsloading(true);
            connect({ connector: connectors[3] });
          }}
        >
          <img
            src={`/images/shared/${
              WALLET_NAMES[
                connectors[3].id.toLowerCase() as keyof typeof WALLET_NAMES
              ]
            }.svg`}
            alt={`hashmail | ${connectors[3].name}-icon`}
            className="h-8 w-8"
          />

          <span className="text-base font-normal">
            {connectors[3].name.toLowerCase()}
          </span>
        </Button>
      </div>
    </div>
  );
};

EVM.displayName = "EVM";
export default forwardRef(EVM);
