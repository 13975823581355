const SenderAddress = process.env.NEXT_PUBLIC_SENDER_ADDRESS;
const SendApi = process.env.NEXT_PUBLIC_SEND_API;
const BEARER_TOKEN = process.env.NEXT_PUBLIC_DAPP_TOKEN;

export const useRestApi = () => {
	const SendMessage = async (
		to_address: string,
		subject: string,
		content: string
	): Promise<void> => {
		let formData = new FormData();
		// formData.append("sender_address", SenderAddress ?? "");
		formData.append("to_address", to_address);
		formData.append("subject", subject);
		formData.append("content", content);

		const myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${BEARER_TOKEN}`);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formData,
			redirect: "follow" as RequestRedirect,
		};

		const resp = await fetch(`${SendApi}`, requestOptions);

		const { data, error } = await resp.json();
		// setTimeout(() => {
		// 	window.hashmail.executeImmediatePoll();
		// }, 5000);

		if (error) throw error;

		return data;
	};
	return {
		SendMessage,
	};
};
export const Truncate = (text: string, start: number, end: number): string => {
	if (text) {
		if (start + end >= text.length) return text;
		return `${text.slice(0, start)}...${text.slice(-end)}`;
	}
	return "";
};
