import { ModalProps } from "utils/types/shared.types";

const Modal: React.FC<ModalProps> = ({
  open,
  setOpen,
  children,
  className,
  disableClose,
}) => {
  return (
    <>
      <div
        onClick={() => !disableClose && setOpen(false)}
        className={`fixed left-0 top-0 z-0 flex h-full w-full flex-col justify-center bg-hash-dark bg-opacity-70 backdrop-blur ${
          open ? "" : "hidden"
        }`}
      >
        <div onClick={(e) => e.stopPropagation()} className={className}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
