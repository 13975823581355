import { Navbar } from "components/shared";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const MainLayout = (props: Props) => {
  return (
    <section className="flex h-screen w-screen flex-col">
      <Navbar />
      <main className="flex h-full flex-col justify-center pt-24 md:pt-32">
        {props.children}
      </main>
    </section>
  );
};

export default MainLayout;
