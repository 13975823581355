/* eslint-disable @next/next/no-img-element */
import GlobalContext from "context/GlobalContext";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { NAVBAR_ROUTES } from "utils/constants/component.constants";
import { Truncate } from "utils/functions";

const Navbar: React.FC = () => {
  const connectHandler = () => {
    window.postMessage("HASHMAIL-CONNECT-HANDLER");
  };

  const router = useRouter();
  const { pathname, query } = router;
  console.log(pathname);

  const { walletIsConnected, connectedAddress } = useContext(GlobalContext);
  return (
    <header className="fixed top-0 w-screen">
      <nav className="flex items-center justify-between p-6 md:p-9">
        <div className="flex justify-start gap-x-10">
          <figure className="flex h-fit w-32 md:w-40 ">
            <img
              src="/images/brand/hashmail-console-logo-alt.svg"
              alt="hashmail"
              className="w-full"
            />
          </figure>

          <div className="hidden items-center gap-x-8 lg:flex">
            {NAVBAR_ROUTES.map(({ content, external, href }) => (
              <Link key={content as string} href={href} passHref legacyBehavior>
                <a
                  target={external ? "_blank" : ""}
                  rel="noopener noreferrer"
                  className="text-md xl:text-md relative border-b-2 border-transparent pb-1 font-light text-raisin-black transition-all after:absolute after:bottom-0 after:left-1/2 after:mt-1 after:h-1 after:w-0 after:transition-all after:duration-300 hover:font-medium hover:after:left-0 hover:after:w-full hover:after:bg-hash-red"
                >
                  {content}
                </a>
              </Link>
            ))}
          </div>
        </div>
        {pathname !== "/nowalletconnectivity" ? (
          <button
            onClick={connectHandler}
            className="item-center relative z-0  flex content-center items-center justify-center rounded  border-2 border-hash-red  px-4 py-2 text-center text-sm lowercase transition-all after:absolute after:left-0 after:-z-10 after:h-full after:w-0 after:bg-hash-red after:transition-all after:content-[''] hover:text-hash-light hover:after:w-full md:text-base"
          >
            {walletIsConnected
              ? Truncate(connectedAddress, 5, 5)
              : " connect wallet"}
          </button>
        ) : (
          ""
        )}
      </nav>
    </header>
  );
};

export default Navbar;
