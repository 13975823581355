export { default as UnreadMailImage } from "./unread-mail.svg";
export { default as Error404Image } from "./error-404.svg";
export { default as Error500Image } from "./error-500.svg";
export { default as DeviceNotSupportImage } from "./device-version-error.svg";
export { default as HamburgerImage } from "./hamburger.svg";
export { default as EnsImage } from "./ens.svg";
export { default as LensImage } from "./lens.svg";
export { default as UnsupportableDomainImage } from "./unstoppable.svg";
export { default as SolanaImage } from "./solana_logo.svg";
export { default as BonfidaImage } from "./bonfida_logo.svg";
export { default as DottedLineImage } from "./dotted.line.svg";

